html,
body {
    height: 100% !important;
}

div#root {
    height: 100%;
}

div.loginLeftPanel {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    background-size: cover !important;
}

.card {
    border: none;
    box-shadow: none;
}

.loginLeftPanel h1 {
    font-size: 50px;
}

.loginLeftPanel h4 {
    font-size: 20px;
}

div.main-div {
    height: 100% !important;
    width: 100% !important;
}

div.loginRightPanel {
    height: 100% !important;
}

@media only screen and (max-width: 600px) {
    .loginLeftPanel {
        display: none !important;
    }
}